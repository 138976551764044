import React, { useRef, useEffect } from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
// import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ContactForm from "../../components/contactForm/ContactForm";
import ConsultationForm from "../../components/consultationForm/ConsultationForm";

const Home = (props) => {
  const stackHeadingRef = useRef();
  const contactUsHeadingRef = useRef();
  const consultationHeadingRef = useRef();

  const theme = props.theme;

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const urlAction = queryParameters.get("action");
    if (urlAction === "contactus") {
      handleScrollToContact();
    }
    if (urlAction === "consultation") {
      handleScrollToConsultation();
    }
  });

  const handleScrollToStack = () => {
    return stackHeadingRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToContact = () => {
    return contactUsHeadingRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToConsultation = () => {
    return consultationHeadingRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Header
        scrollToStack={handleScrollToStack}
        scrollToContactUs={handleScrollToContact}
        scrollToConsultation={handleScrollToConsultation}
        theme={theme}
      />
      <Greeting
        scrollToStack={handleScrollToStack}
        scrollToConsultation={handleScrollToConsultation}
        theme={theme}
      />
      <Skills ref={stackHeadingRef} theme={theme} />
      <ContactForm ref={contactUsHeadingRef} theme={theme} />
      {/* <Footer theme={this.props.theme} /> */}
      <ConsultationForm ref={consultationHeadingRef} theme={theme} />
      <TopButton theme={theme} />
    </div>
  );
};

export default Home;
