import React from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { greeting } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};
const logo_path = greeting.logo_path;
const logo_name = greeting.logo_name;
const Header = (props) => {
  const theme = props.theme;
  // const link = settings.isSplash ? "/splash" : "home";
  return (
    <Fade top duration={1000} distance="20px">
      <SeoHeader />
      <div>
        <header className="header">
          <NavLink to={"/"} tag={Link} className="logo">
            <span className="logo-header" style={{ color: theme.text }}>
              <img
                src={require(`../../assests/images/${logo_path}`)}
                alt={logo_name}
              />
            </span>
          </NavLink>

          {/* <NavLink to={"/"} tag={Link} className="logo">
            <span style={{ color: theme.text }}> &lt;</span>
            <span className="logo-name" style={{ color: theme.text }}>
              {logo_name}
            </span>
            <span style={{ color: theme.text }}>/&gt;</span>
          </NavLink> */}
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu" style={{ backgroundColor: theme.body }}>
            {/* <li>
                <NavLink
                  to="/home"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Home
                </NavLink>
              </li> */}
            {/* <li>
                <NavLink
                  to="/education"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Education
                </NavLink>
              </li> */}
            <li>
              <NavLink
                to=""
                tag={Link}
                style={{ color: theme.text }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
                onClick={props.scrollToStack}
              >
                Services
              </NavLink>
            </li>
            {/* <li>
                <span
                  style={{
                    color: theme.text,
                    cursor: 'pointer'
                  }}
                  
                  onClick={props.scrollToStack}
                >
                  Services
                </span>
              </li> */}
            {/* <li>
                <NavLink
                  to="/opensource"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Open Source
                </NavLink>
              </li> */}
            <li>
              <NavLink
                to=""
                tag={Link}
                style={{ color: theme.text }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
                onClick={props.scrollToContactUs}
              >
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to=""
                tag={Link}
                style={{ color: theme.text }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
                onClick={props.scrollToConsultation}
              >
                Consultation
              </NavLink>
            </li>
          </ul>
        </header>
      </div>
    </Fade>
  );
};
export default Header;
