import React from "react";
import { useForm } from "react-hook-form";
// import { greeting } from "../../portfolio";

const ConsultationForm = React.forwardRef((props, ref) => {
  const {
    register,
    formState: { errors },
  } = useForm();

  return (
    <div className="main" style={{ marginBottom: "200px" }}>
      <div style={{ textAlign: "center" }}>
        <h1
          id="consultation-heading"
          className="skills-header greeting-text"
          style={{ color: props.theme.text }}
        >
          Request a Free Consultation
        </h1>
        <p
          className="text-center greeting-text-p subTitle"
          style={{ color: props.theme.secondaryText }}
        >
          Automate and expand your business process. Request a free consultation
          now!
          <div>We'll schedule you by dawn</div>
        </p>
      </div>
      <form name="consultation" method="post">
        <input type="hidden" name="form-name" value="consultation" />
        <label>Phone or Email</label>
        <input
          {...register("email", { required: "Email Address is required" })}
          aria-invalid={errors.mail ? "true" : "false"}
        />
        {errors.mail && <p role="alert">{errors.mail?.message}</p>}
        <label for="feature" ref={ref}>
          Provide any information for your request(Optional)
        </label>
        <textarea {...register("feature")} />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
});
export default ConsultationForm;
