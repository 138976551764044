import React from "react";
import { useForm } from "react-hook-form";
// import { greeting } from "../../portfolio";

import "./ContactForm.css";

const ContactForm = React.forwardRef((props, ref) => {
  const { register } = useForm();
  return (
    <div className="main" style={{ marginBottom: "100px" }}>
      <div style={{ textAlign: "center" }}>
        <h1
          ref={ref}
          className="skills-header greeting-text"
          style={{ color: props.theme.text }}
        >
          Contact Us
          <strong
            style={{ fontSize: "38%", opacity: "0.75", display: "block" }}
          >
            Email:. info@neybapps.com. Text/Call:. 208-538-9522
          </strong>
        </h1>
        <p
          className="text-center greeting-text-p subTitle"
          style={{ color: props.theme.secondaryText }}
        >
          Submit any inquiries. We'll respond before the end of the day
        </p>
      </div>
      <form name="contact" method="post">
        <input type="hidden" name="form-name" value="contact" />
        <label>Name</label>
        <input {...register("name")} />
        <label>Email</label>
        <input {...register("email")} />
        <label for="phone">Phone number(Optional)</label>
        <input type="tel" name="phone" />
        <label>Inquiry</label>
        <textarea {...register("inquiry")} />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
});
export default ContactForm;
