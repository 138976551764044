import React from "react";
import "./Skills.css";
import SkillSection from "./SkillSection";
const Skills = React.forwardRef((props, ref) => {
  const theme = props.theme;

  return (
    <div className="main" id="skills">
      <div className="skills-header-div">
        <h1 ref={ref} className="skills-header" style={{ color: theme.text }}>
          Our Services and Stacks
        </h1>
      </div>
      <SkillSection theme={theme} />
    </div>
  );
});

export default Skills;
